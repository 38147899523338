import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueResource from "vue-resource";

Vue.use(vuetify);
Vue.use(VueResource);

Vue.config.productionTip = false;

Vue.http.options.root = "https://api-licencias.nisiracloud.com.pe/";
//Vue.http.options.root = "http://localhost:3001/";

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
