<template>
  <v-app>
    <router-view/>
  </v-app>
</template>

<script>
import Home from './views/Home.vue';

export default {
  name: 'App',

  components: {
    Home,
  },
};
</script>
